<template>
  <div class="contract view pd-20" id="departure-reports">
    <a-page-header style="padding: 0">
      <div slot="title">
        <h1>
          <img
            class="mr-5"
            src="@/assets/images/dashboard/send-sms-colorful.png"
            width="30" alt="img"
          />
          SMS
        </h1>
      </div>
      <div slot="extra">
        <a-button type="primary" ghost @click="openSmsModal('new', undefined)">
          <img
            class="mr-5"
            src="@/assets/images/dashboard/send-sms.png"
            width="15"  alt="img"
          />
          Nova SMS
        </a-button>

        <a-modal
          :visible="openCreateSmsModal"
          :footer="false"
          @cancel="clearSmsModalData"
          width="900px"
        >
          <template slot="title">
            <a-row type="flex" justify="space-between">
              <a-col> ENVIAR SMS </a-col>
              <a-col
                class="cgreen"
                style="padding-right: 50px; font-size: 12px"
              >
                <a-icon type="dollar" /> Créditos:
                {{ totalCredits }}
              </a-col>
            </a-row>
          </template>
          <a-row :gutter="[20, 20]">
            <div>
              <a-col :span="24">
                <label class="label"> Remetente: </label>
                <div class="travel-input">
                  <a-input
                    style="width: 200px"
                    v-model="notification.sender"
                    placeholder="Remetente"
                  />
                </div>
              </a-col>
            </div>

            <a-col :span="24">
              <label class="label mb-10"> Selecione o destinatário: </label>
              <a-radio-group v-model="notification.receiver" name="radioGroup">
                <a-radio value="all-customers">
                  Todos clientes
                  <i style="font-size: 11px"
                    >({{ customers.pagination.total_general }})</i
                  >
                </a-radio>

                <a-radio value="custom-customers">
                  Personalizar clientes
                  <i style="font-size: 11px"
                    >({{ customers.pagination.total }})</i
                  >
                </a-radio>

                <a-radio value="specific-number"> Número Específico </a-radio>
              </a-radio-group>
            </a-col>

            <a-col
              v-if="notification.receiver == 'custom-customers'"
              :span="24"
            >
              <label class="label">
                Selecione o estado do cliente (opcional):
              </label>
              <a-select
                class="travel-input travel-multiselector"
                placeholder="Estado"
                show-search
                v-model="customers.filters.states.selected"
                mode="multiple"
                style="width: 100%"
                optionFilterProp="txt"
                @change="getCustomers()"
              >
                <a-select-option
                  v-for="(item, index) of states"
                  :key="index"
                  :value="item.initials"
                  :txt="removeStrSpecialChars(item.name)"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>

            <div v-if="notification.receiver == 'specific-number'">
              <a-col class="travel-input" :span="24">
                <label class="label mb-10"> DDD + Número </label>
                <a-input
                  style="width: 200px"
                  v-model="notification.number"
                  placeholder="DDD + Número"
                />
              </a-col>
            </div>

            <div v-if="theSMS.type == 'contract'">
              <a-col :span="24">
                <label class="label mb-10"> Copie uma das mensagens: </label>

                <div
                  v-for="(smsMessage, index) in smsOptions()"
                  :key="index"
                  style="margin: 15px 0"
                >
                  <a-textarea
                    v-model="smsMessage.template"
                    placeholder="Digite o texto aqui. Maximo de 160 caractéres."
                    :rows="2"
                    :disabled="true"
                    style="
                      color: #333;
                      font-size: 12px;
                      font-weight: 600;
                      padding: 10px;
                      border-width: 1px;
                    "
                  />
                </div>
              </a-col>
            </div>

            <a-col :span="24">
              <label class="label mb-10"> Mensagem: </label>

              <a-textarea
                v-model="notification.content"
                placeholder="Digite o texto aqui. Maximo de 160 caractéres."
                :rows="4"
                maxLength="160"
              />

              <a-row type="flex" justify="space-between">
                <a-col>
                  <div
                    v-show="notification.receiver != 'specific-number'"
                    class="red"
                    style="font-size: 11px; margin-top: 8px; color: #333"
                  >
                    *A tag [nome] é obrigatoria.
                  </div>
                </a-col>
                <a-col>
                  <div
                    style="font-size: 12px; text-align: right; margin-top: 8px"
                  >
                    {{ notification.content.length }} / 160
                  </div>
                </a-col>
              </a-row>
            </a-col>

            <a-col :span="24">
              <a-popconfirm
                title="Tem certeza que deseja enviar o SMS?"
                ok-text="Sim"
                cancel-text="Não"
                @confirm="sendSMS()"
              >
                <a-button
                  type="primary"
                  :disabled="totalCredits > 0 ? false : true"
                  :loading="notification.loading"
                >
                  <i class="far fa-paper-plane mr-10"></i>
                  Enviar SMS
                </a-button>
                <font class="ml-20 f11 corange" v-show="totalCredits == 0">
                  Sem créditos para envio.
                </font>
              </a-popconfirm>
            </a-col>
          </a-row>
        </a-modal>
      </div>
    </a-page-header>

    <a-collapse
      class="travel-filters expandable mt-0 mb-0"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>
        <a-row class="mt-0" :gutter="20">
          <a-col :span="2">
            <div class="travel-input">
              <label class="filled">ID</label>
              <a-input
                placeholder="Insira o ID"
                v-model="filters.id"
                @change="getContracts()"
              />
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Período</label>
              <a-range-picker
                v-model="filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getContracts()"
              />
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Embarque</label>
              <a-range-picker
                v-model="filters.embark.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getContracts()"
              />
            </div>
          </a-col>

          <a-col :span="6" style="display: none">
            <div class="travel-input">
              <label class="filled">Status</label>
              <a-select
                class="travel-input"
                placeholder="Selecione o status do contrato"
                mode="multiple"
                v-model="filters.status.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of filters.status.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :columns="columns"
      :data-source="contractList"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="contractsTableChange"
    >
      <div slot="created" slot-scope="record">
        {{
          record.theContract.release_date == "0000-00-00"
            ? formatDateTime(record.created)
            : formatDate(record.theContract.release_date)
        }}
      </div>
      <div slot="traveller_type" slot-scope="record">
        {{ formatTravellers(record.theContract.meta).txt }}
      </div>
      <a slot="id" slot-scope="text"> {{ text }}</a>
      <a slot="contractor" slot-scope="text">{{ text }}</a>
      <template slot="product" slot-scope="record">
        <div :class="record">
          <a-tooltip
            class="travel-tooltip"
            v-for="product in record"
            :key="product"
            placement="top"
          >
            <template slot="title">
              <div v-html="product.name" />
            </template>
            <a-tag :color="product.color">
              <div v-html="product.name" />
            </a-tag>
            <div class="clearfix" />
          </a-tooltip>
        </div>
      </template>

      <div slot="contact" slot-scope="record">
        {{
          record.theContract.customer
            ? record.theContract.customer.mobile_phone
            : record.theContract.meta.client_mobile_phone
        }}
      </div>
      <div slot="checkin" slot-scope="record">
        {{ formatCheckin(record.theContract.meta) }}
      </div>
      <div slot="checkout" slot-scope="record">
        {{ formatCheckout(record.theContract.meta) }}
      </div>
      <div slot="totalValue" slot-scope="record" v-html="record"></div>
      <div slot="passengers" slot-scope="record">
        {{ formatPassengers(record.theContract.meta) }}
      </div>
      <div slot="payments" slot-scope="record">
        <a-tag
          class="ant-tag-price"
          v-for="(payment, index) in record"
          :key="index"
        >
          <span v-html="payment.name" />
        </a-tag>
      </div>
      <a slot="company" slot-scope="text">{{ text }}</a>
      <template class="travel-actions" slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a-tooltip placement="top" title="Enviar SMS">
            <a
              class="view ml-15 grayscale"
              @click="openSmsModal('contract', record)"
              style="font-size: 30px; opacity: 0.6"
            >
              <img
                src="@/assets/images/dashboard/send-sms-small.png"
                width="25"  alt="img"
              />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="pagination.page"
        :page-size-options="pageSizeOptions"
        :total="pagination.total"
        @change="changePage"
        @showSizeChange="changePageSize"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span v-if="props.value !== '99999'">{{ props.value }}/página</span>
          <span v-if="props.value === '99999'"> Todos</span>
        </template>
      </a-pagination>
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import customerMixins from "@/mixins/customers/customerMixins.js";
import useGeneratePDF from "@/composables/generatePDF.js";
import Airports from "@/mixins/internal-apis/airports.json";
import states from "@/json/states";
import { format } from "date-fns";

export default {
  name: "Contracts",
  mixins: [formatThings, customerMixins, Airports],
  components: {},
  setup() {
    const { generatePDF } = useGeneratePDF();
    return { generatePDF };
  },
  data() {
    return {
      theSMS: {},
      states,
      totalCredits: 0,
      airports: Airports,
      openCreateSmsModal: false,
      notification: {
        role: undefined,
        title: undefined,
        sender: "Viajar Resorts",
        content: "",
        receiver: "all-customers",
        states: [],
        user_id: undefined,
        url: undefined,
        loading: false,
      },
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "travel", "strike"],
        [
          "align",
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          "link",
          "blockquote",
          {
            color: [],
          },
          {
            background: [],
          },
        ],
        ["clean"],
      ],
      pageSizeOptions: ["10", "25", "50", "100", "200", "500", "1000", "99999"],
      reports: {
        all: {
          text: "Contratos",
          total: 0,
          totalSold: 0,
          percentage: 0,
        },
        viajarResorts: { total: 0, totalSold: 0, percentage: 0 },
        voeSimples: {
          total: 0,
          totalSold: 0,
          percentage: 0,
        },
        credimilhas: { total: 0, totalSold: 0, percentage: 0 },
      },
      openFilters: 1,
      filters: {
        loadingResults: false,
        id: "",
        paymentMethods: {
          list: [
            {
              label: "Cartão de Crédito",
              value: "credit-card",
            },
            {
              label: "Boleto",
              value: "billing-ticket",
            },
            {
              label: "Transferência Bancária",
              value: "bank-transfer",
            },
            {
              label: "Carta de Crédito",
              value: "payment-card",
            },
            {
              label: "Link de Pagamento",
              value: "payment-link",
            },
          ],
          selected: [],
        },
        companies: {
          list: [
            {
              label: "Viajar Resorts",
              value: "Viajar Resorts",
            },
            {
              label: "Voe Simples",
              value: "Voe Simples",
            },
            {
              label: "Credimilhas",
              value: "Credimilhas",
            },
          ],
          selected: [],
        },
        personTypes: {
          list: [
            {
              label: "Pessoa Física",
              value: "Pessoa Física",
            },
            {
              label: "Pessoa Jurídica",
              value: "Pessoa Jurídica",
            },
          ],
          selected: [],
        },
        users: {
          list: [],
          selected: [],
        },
        productsCategory: {
          list: [
            {
              label: "Hotéis/Resorts",
              value: "hotel",
            },
            {
              label: "Aéreo",
              value: "flight",
            },
            {
              label: "Serviços",
              value: "service",
            },
          ],
          selected: [],
        },
        products: {
          list: [],
          selected: [],
        },
        clients: {
          list: [],
          selected: [],
        },
        status: {
          list: [
            {
              label: "Concluído",
              value: "concluded",
            },
            {
              label: "Em edição",
              value: "edit",
            },
            {
              label: "Cancelado",
              value: "canceled",
            },
          ],
          selected: ["concluded"],
        },
        embark: {
          selected: [],
        },
        period: {
          selected: [],
        },
        searchTerm: "",
        price: {
          min: "",
          max: "",
        },
        order: "desc",
        orderBy: "created",
      },
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
        totalPages: 0,
      },
      cancelContract: {
        id: null,
        modal: false,
        loading: false,
        reason: null,
        contract: {},
      },
      showGlobalActions: false,
      contractList: [],
      totalSold: 0,
      totalContracts: 0,
      idInfotera: null,
      infoteraLoading: false,
      infoteraData: [],
      createdId: null,
      contractData: {},
      disableButton: false,
      form: this.$form.createForm(this),
      showDocuments: false,
      documents: {
        documentsSelector: [],
        documentSelectorIsOpen: false,
        viajarResorts: [
          {
            id: 1,
            name: "Viajar Resorts - Documentos - Padrão",
          },
        ],
        voeSimples: [
          {
            id: 2,
            name: "Voe Simples - Documento - Federal",
          },
        ],
      },
      columns: [
        {
          title: "ID",
          sorter: true,
          dataIndex: "id",
          key: "id",
          width: 100,
        },
        {
          title: "Data da venda",
          sorter: true,
          scopedSlots: { customRender: "created" },
          width: 150,
        },
        {
          title: "Produtos",
          dataIndex: "product",
          key: "product",
          scopedSlots: { customRender: "product" },
          width: 300,
        },
        {
          title: "Check-in",
          key: "checkin",
          scopedSlots: { customRender: "checkin" },
          width: 120,
        },
        {
          title: "Check-out",
          key: "checkout",
          scopedSlots: { customRender: "checkout" },
          width: 120,
        },
        {
          title: "Passageiros",
          key: "passengers",
          scopedSlots: { customRender: "passengers" },
          width: 100,
        },
        {
          title: "Pax",
          scopedSlots: { customRender: "traveller_type" },
          width: 200,
        },
        {
          title: "Valor Total",
          dataIndex: "totalValue",
          key: "totalValue",
          scopedSlots: { customRender: "totalValue" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          fixed: this.$root.isAdmin() ? false : "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      companies: [],
      defaultCompany: "Viajar Resorts",
      personTypes: [
        { label: "Pessoa Física", value: "Pessoa Física" },
        { label: "Pessoa Jurídica", value: "Pessoa Jurídica" },
      ],
      defaultPersonType: "Pessoa Física",
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          selectedRowKeys;
          selectedRows;

          this.showGlobalActions = true;

          if (selectedRows.length == 0) {
            this.showGlobalActions = false;
          }
        },
        onSelect: (record, selected, selectedRows) => {
          record;
          selected;

          this.showGlobalActions = true;

          console.log(
            "onselect",
            selectedRows.map((item) => {
              return item.id;
            })
          );

          if (selectedRows.length == 0) {
            this.showGlobalActions = false;
          }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          selected;
          changeRows;

          this.showGlobalActions = true;

          console.log(
            "onselectalla",
            selectedRows.map((item) => {
              return item.id;
            })
          );

          if (selectedRows.length == 0) {
            this.showGlobalActions = false;
          }
        },
      },
      loading: false,
      visible: false,
      openUpdateHistory: false,
      theContract: [],
    };
  },
  beforeMount() {

    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);

    firstDay = format(firstDay, "yyyy-MM-dd");
    lastDay = format(lastDay, "yyyy-MM-dd");

    this.filters.period.selected = [`${firstDay}`, `${lastDay}`];

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("id")) {
      this.filters.id = urlParams.get("id");
    }

    if (urlParams.get("is-ticketed")) {
      this.filters.ticketed = urlParams.get("is-ticketed");
    }

    if (urlParams.get("period")) {
      this.filters.period.selected = urlParams.get("period").split("|");
    }

    console.log("queryString", urlParams.get("id"));

    this.getContracts();

    document.title = "Marketing por SMS";
  },
  methods: {
    getSmsCredits() {
      this.$http.get(`/sms/credits`).then(({ data }) => {
        this.totalCredits = data.total;
      });
    },
    clearSmsModalData() {
      this.openCreateSmsModal = false;

      setTimeout(() => {
        this.notification = {
          role: undefined,
          title: undefined,
          sender: "Viajar Resorts",
          content: "",
          receiver: "all-customers",
          states: [],
          user_id: undefined,
          url: undefined,
          loading: false,
        };
      }, 100);
    },
    smsOptions() {
      let theOptions = [];
      let allProducts = [];
      let phone = "";
      let hotelName = "";
      let hotelDiet = "";
      let origin = "";
      let destination = "";

      let contractedServices = JSON.parse(
        this.theSMS.contract.theContract.meta.contracted_services
      );

      this.notification.sender = this.theSMS.contract.theContract.company;

      if (this.theSMS.contract.theContract.company == "Viajar Resorts") {
        phone = "0800 291 2251";
      } else {
        phone = "0800 941 9909";
      }

      if (contractedServices) {
        contractedServices.forEach((p) => {
          // HOTEL
          if (p == "hotel") {
            JSON.parse(
              this.theSMS.contract.theContract.meta.hotel_rows
            ).forEach((row) => {
              if (
                this.theSMS.contract.theContract.meta[`hotel_${row.id}_name`] !=
                undefined
              ) {
                allProducts.push({
                  name: this.theSMS.contract.theContract.meta[
                    `hotel_${row.id}_name`
                  ]
                    ? this.theSMS.contract.theContract.meta[
                        `hotel_${row.id}_name`
                      ]
                    : `HOTEL ${row.id}`,
                });
              }
            });

            hotelName =
              this.theSMS.contract.theContract.meta[`hotel_${1}_name`];

            hotelDiet =
              this.theSMS.contract.theContract.meta[`hotel_${1}_diet`];

            theOptions.push({
              template: `Olá [nome]! Mega oferta ${hotelName}, ${this.formatMultiDates(
                this.theSMS.contract.theContract.meta[`hotel_${1}_checkin`]
              )}, ${
                this.theSMS.contract.theContract.meta[`hotel_${1}_diet`]
              } para ${
                this.formatTravellers(this.theSMS.contract.theContract.meta).txt
              } por apenas ${this.formatPrice(
                this.theSMS.contract.theContract.meta[`hotel_${1}_total_price`]
              )}, 10x sem juros. Ligue agora para ${phone}!`,
              label: `Olá ${
                this.$store.state.userData.first_name
              }! Mega oferta ${
                this.theSMS.contract.theContract.meta[`hotel_${1}_name`]
              }, ${this.formatMultiDates(
                this.theSMS.contract.theContract.meta[`hotel_${1}_checkin`]
              )}, ${
                this.theSMS.contract.theContract.meta[`hotel_${1}_diet`]
              } para ${
                this.formatTravellers(this.theSMS.contract.theContract.meta).txt
              } por apenas ${this.formatPrice(
                this.theSMS.contract.theContract.meta[`hotel_${1}_total_price`]
              )}, 10x sem juros. Ligue agora para ${phone}!`,
            });
          }

          // AÉREO
          if (p == "flight") {
            if (
              this.theSMS.contract.theContract.meta.flight_rows != undefined &&
              this.theSMS.contract.theContract.meta.flight_sections_rows ==
                undefined
            ) {
              JSON.parse(
                this.theSMS.contract.theContract.meta.flight_rows
              ).forEach((row, index) => {
                if (index == 0) {
                  origin =
                    this.theSMS.contract.theContract.meta[
                      `flight_${row.id}_origin`
                    ];

                  allProducts.push({
                    name: `AÉREO - ${
                      this.theSMS.contract.theContract.meta[
                        `flight_${row.id}_class`
                      ]
                    } - SAíDA: ${origin}`,
                  });
                }
              });
            }

            if (
              this.theSMS.contract.theContract.meta.flight_sections_rows !=
              undefined
            ) {
              let type = "";
              JSON.parse(
                this.theSMS.contract.theContract.meta.flight_sections_rows
              ).forEach((flight) => {
                flight.sections.forEach((section, index) => {
                  index;

                  type = ` (${
                    this.theSMS.contract.theContract.meta[
                      `flight_${flight.id}_section_${section.id}_type`
                    ]
                  }) `;

                  if (type == " (undefined) ") {
                    type = "";
                  }

                  if (index == 0) {
                    origin =
                      this.theSMS.contract.theContract.meta[
                        `flight_${flight.id}_section_${section.id}_origin`
                      ];
                    allProducts.push({
                      name: `AÉREO ${flight.id}  ${type} - ${
                        this.theSMS.contract.theContract.meta[
                          `flight_${flight.id}_section_${section.id}_class`
                        ]
                      } - ${origin} <i class="fas fa-plane" style="margin: 0 2px; font-size: 8px; top:-1px; position: relative;"></i>  ${
                        this.theSMS.contract.theContract.meta[
                          `flight_${flight.id}_section_${section.id}_destination`
                        ]
                      }`,
                    });
                  }

                  if (index == flight.sections.length - 1) {
                    destination =
                      this.theSMS.contract.theContract.meta[
                        `flight_${flight.id}_section_${section.id}_origin`
                      ].toUpperCase();

                    allProducts.push({
                      name: `AÉREO ${flight.id} ${type} - ${
                        this.theSMS.contract.theContract.meta[
                          `flight_${flight.id}_section_${section.id}_class`
                        ]
                      } - ${
                        this.theSMS.contract.theContract.meta[
                          `flight_${flight.id}_section_${section.id}_origin`
                        ]
                      } <i class="fas fa-plane" style="margin: 0 2px; font-size: 8px; top:-1px; position: relative;"></i>  ${
                        this.theSMS.contract.theContract.meta[
                          `flight_${flight.id}_section_${section.id}_destination`
                        ]
                      }`,
                    });
                  }
                });
              });
            }

            theOptions.push({
              template: `[nome], passagem aérea em classe ${
                this.theSMS.contract.theContract.meta[
                  `flight_1_section_1_class`
                ]
              } para ${this.theAirport(
                destination
              )} por apenas ${this.formatPrice(
                this.theSMS.contract.theContract.meta[`flight_1_total_price`]
              )} em até 5x sem juros! Ligue agora para ${phone}! `,
            });
          }

          // SERVIÇOS

          if (p == "service") {
            JSON.parse(
              this.theSMS.contract.theContract.meta.service_rows
            ).forEach((row) => {
              if (
                this.theSMS.contract.theContract.meta[
                  `service_${row.id}_name`
                ] != undefined
              ) {
                allProducts.push({
                  color: "orange",
                  name: this.theSMS.contract.theContract.meta[
                    `service_${row.id}_name`
                  ],
                });
              }
            });
          }
        });

        if (
          contractedServices.includes("hotel") &&
          contractedServices.includes("flight")
        ) {
          theOptions.push({
            template: `Boa Tarde [nome]! Pacote completo para ${hotelName}, ${hotelDiet}, saindo de ${this.theAirport(
              origin
            )}, ${this.formatCheckin(
              this.theSMS.contract.theContract.meta
            )},  para ${
              this.formatTravellers(this.theSMS.contract.theContract.meta).txt
            } por apenas ${
              this.theSMS.contract.totalValue
            } em 10x sem juros. Ligue agora para ${phone} e aproveite esta oferta!`,
          });

          theOptions.push({
            template: `[nome], reserve suas férias! Pacote completo para  ${hotelName}, saindo de ${this.theAirport(
              origin
            )}, ${this.formatCheckin(
              this.theSMS.contract.theContract.meta
            )}, ${hotelDiet} para ${
              this.formatTravellers(this.theSMS.contract.theContract.meta).txt
            } por apenas ${
              this.theSMS.contract.totalValue
            }. Ligue agora para ${phone}.`,
          });

          theOptions.push({
            template: `Viaje para  ${this.theAirport(
              destination
            )} por apenas  ${this.formatPrice(
              this.theSMS.contract.theContract.value
            )}! [nome] Promoção para  ${hotelName} por apenas ${
              this.theSMS.contract.totalValue
            } em até 10x sem juros. Ligue agora para ${phone}!`,
          });
        }
      }

      allProducts;

      return theOptions;
    },
    theAirport(name) {
      let theAirport = this.airports.filter((airport) => {
        return airport.sigla == name;
      });

      return theAirport[0].nomeaeroporto;
    },
    openSmsModal(type, contract) {
      this.theSMS = {
        type,
        contract,
      };

      this.getSmsCredits();

      console.log("openSmsModal", type, contract);
      this.openCreateSmsModal = true;
    },
    sendSMS() {
      if (this.notification.content) {
        this.notification.loading = true;

        let states = "";

        if (this.customers.filters.states.selected.length > 0) {
          this.customers.filters.states.selected.forEach((state) => {
            states += `${state},`;
          });
        }

        this.notification.states = states.slice(0, -1);

        this.$http
          .post("/sms/send", this.notification)
          .then(({ data }) => {
            data;
            this.$message.success(data.message);
            this.notification.loading = false;
            this.getSmsCredits();
          })
          .catch(({ response }) => {
            response;
            this.$message.error(response.message);
            this.notification.loading = false;
          });
      } else {
        if (this.theSMS.type == "new") {
          this.$message.warning("Escreva uma mensagem no campo.");
        } else {
          this.$message.warning("Selecione uma opção para enviar.");
        }
      }
    },
    formatCheckin(meta) {
      let theDate = "";

      let services = JSON.parse(meta.contracted_services);

      if (services.includes("hotel")) {
        theDate = this.formatMultiDates(meta.hotel_1_checkin);
      }

      if (services.includes("flight")) {
        theDate = this.formatMultiDates(meta.flight_1_section_1_departure_date);
      }

      return theDate;
    },
    formatCheckout(meta) {
      let theDate = "";

      let services = JSON.parse(meta.contracted_services);
      let flights = [];

      if (services.includes("hotel")) {
        theDate = this.formatMultiDates(meta.hotel_1_checkout);
      }

      if (services.includes("flight")) {
        flights = JSON.parse(meta.flight_sections_rows);

        let totalFlights = flights.length;

        let lastSection = flights[totalFlights - 1].sections.length;

        console.log("lastSection", lastSection, totalFlights);

        theDate = this.formatMultiDates(
          meta[`flight_${totalFlights}_section_${lastSection}_departure_date`]
        );
      }

      return theDate;
    },
    formatTravellers(meta) {
      let allTravellers = [];
      let totalAdults = 0;
      let totalChilds = 0;
      let txtAdts = "";
      let txtChds = "";

      JSON.parse(meta.travellers_rows).forEach((traveller) => {
        allTravellers.push({
          idade: `${meta[`traveller_${traveller.id}_age`]}`,
        });
      });

      allTravellers.forEach((travellerObj) => {
        if (travellerObj.idade == "adult") {
          totalAdults = totalAdults + 1;
        }

        if (travellerObj.idade == "child") {
          totalChilds = totalChilds + 1;
        }
      });

      if (totalAdults == 1) {
        txtAdts = "1 adulto";
      }

      if (totalAdults > 1) {
        txtAdts = totalAdults + " adultos";
      }

      if (totalChilds == 1) {
        txtChds = " + 1 criança";
      }

      if (totalChilds > 1) {
        txtChds = ` + ${totalChilds} crianças`;
      }

      return { txt: txtAdts + txtChds, totalAdults, totalChilds };
    },
    formatPassengers(meta) {
      let travellers = JSON.parse(meta.travellers_rows);
      return travellers.length;
    },
    changePage(current) {
      this.pagination.page = current;
      this.getContracts();
    },
    changePageSize(current, pageSize) {
      this.pagination.page = current;
      this.pagination.perPage = pageSize;
      this.getContracts();
    },
    contractsTableChange(pagination, filters, sorter) {
      //console.log("sorter s", sorter, pagination, filters);
      this.filters.order = sorter.order != undefined ? sorter.order : "desc";
      this.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getContracts();
    },
    getContracts(samePage) {
      this.loading = true;
      let filters = "";
      if (!this.$root.isAdmin()) {
        filters += `&user=${this.$store.state.userData.id}`;
      } else {
        if (this.filters.users.selected.length > 0) {
          filters += `&user=${this.filters.users.selected}`;
        }
      }
      if (this.filters.companies.selected.length > 0) {
        filters += `&company=${this.filters.companies.selected}`;
      }

      if (this.filters.status.selected.length > 0) {
        filters += `&status=${this.filters.status.selected}`;
      }

      if (this.filters.paymentMethods.selected.length > 0) {
        filters += `&payment-methods=${this.filters.paymentMethods.selected}`;
      }

      if (this.filters.personTypes.selected.length > 0) {
        filters += `&person-type=${this.filters.personTypes.selected}`;
      }

      if (this.filters.productsCategory.selected.length > 0) {
        filters += `&product-categories=${this.filters.productsCategory.selected}`;
      }

      if (this.filters.embark.selected.length > 0) {
        filters += `&embark=${this.filters.embark.selected[0]}|${this.filters.embark.selected[1]}`;
      }

      if (this.filters.period.selected.length > 0) {
        filters += `&period=${this.filters.period.selected[0]}|${this.filters.period.selected[1]}`;
      }

      if (this.filters.price.min || this.filters.price.max) {
        let min = this.filters.price.min;
        let max = this.filters.price.max;

        if (min) {
          if (!max) {
            max = "500000";
          }
        }

        if (max) {
          if (!min) {
            min = "0";
          }
        }

        filters += `&price-range=${min}-${max}`;
      }

      if (this.filters.searchTerm) {
        filters += `&s=${this.filters.searchTerm}`;
      }

      if (this.filters.id) {
        filters += `&id=${this.filters.id}`;
      }

      filters += `&order=${this.filters.order}&order-by=${this.filters.orderBy}`;

      this.$http
        .get(
          `/contract/list?page=${this.pagination.page}&per_page=${this.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.pagination.page = 1;
          }

          this.pagination.total = data.meta.total;
          this.pagination.totalPages = data.meta.total_pages;

          this.totalSold = data.meta.total_sold;
          this.totalContracts = data.meta.total;

          this.reports.all.total = data.meta.all_total;
          this.reports.all.percentage = data.meta.all_total_percentage;

          this.contractList = data.data.map((contract) => {
            return {
              id: contract.id,

              product:
                this.formatProducts(contract.meta) != "UNDEFINED"
                  ? this.formatProducts(contract.meta)
                  : "NÃO INFORMADO",
              company: contract.company,
              user: `${contract.user.first_name} ${contract.user.last_name}`,
              status: contract.status,
              totalValue: this.formatPrice(
                contract.meta.reservation_total_price
              ),
              changeHistory: contract.update_logs,
              created: contract.created,
              contract_done:
                contract.meta["contract_done"] != undefined
                  ? `${contract.meta.contract_done}`
                  : false,
              theContract: contract,
            };
          });
          this.loading = false;
        })
        .catch(({ response }) => {
          response;
          this.contractList = [];
          this.pagination.total = response.data.meta.total;
          this.pagination.totalPages = response.data.meta.total_pages;

          this.totalSold = response.data.meta.total_sold;
          this.totalContracts = response.data.meta.total;
          this.loading = false;
        });
    },
    getUsers() {
      this.$http
        .get("/user/read")
        .then(({ data }) => {
          this.filters.users.list = data.map((user) => {
            return {
              label: `${user.id} - ${user.first_name} ${user.last_name}`,
              value: user.id,
            };
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    formatPrice(price) {
      return price
        ? `R$ ${Number(parseFloat(price).toFixed(2)).toLocaleString()}`
        : `---`;
    },
    formatProducts(meta) {
      let allProducts = [];

      if (meta.contracted_services) {
        JSON.parse(meta.contracted_services).forEach((p) => {
          // HOTEL
          if (p == "hotel") {
            JSON.parse(meta.hotel_rows).forEach((row) => {
              if (meta[`hotel_${row.id}_name`] != undefined) {
                allProducts.push({
                  color: "green",
                  name: meta[`hotel_${row.id}_name`]
                    ? meta[`hotel_${row.id}_name`]
                    : `HOTEL ${row.id}`,
                });
              }
            });
          }

          // AÉREO
          if (p == "flight") {
            if (
              meta.flight_rows != undefined &&
              meta.flight_sections_rows == undefined
            ) {
              JSON.parse(meta.flight_rows).forEach((row, index) => {
                row;

                if (index == 0) {
                  allProducts.push({
                    color: "blue",
                    name: `AÉREO - ${meta[`flight_${row.id}_class`]} - SAíDA: ${
                      meta[`flight_${row.id}_origin`]
                    }`,
                  });
                }
              });
            }

            if (meta.flight_sections_rows != undefined) {
              let type = "";
              JSON.parse(meta.flight_sections_rows).forEach((flight) => {
                flight.sections.forEach((section, index) => {
                  index;

                  type = ` (${
                    meta[`flight_${flight.id}_section_${section.id}_type`]
                  }) `;

                  if (type == " (undefined) ") {
                    type = "";
                  }

                  if (index == 0) {
                    allProducts.push({
                      color: "blue",
                      name: `AÉREO ${flight.id}  ${type} - ${
                        meta[`flight_${flight.id}_section_${section.id}_class`]
                      } - ${
                        meta[`flight_${flight.id}_section_${section.id}_origin`]
                      } <i class="fas fa-plane" style="margin: 0 2px; font-size: 8px; top:-1px; position: relative;"></i>  ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_destination`
                        ]
                      }`,
                    });
                  }

                  if (index == flight.sections.length - 1) {
                    allProducts.push({
                      color: "blue",
                      name: `AÉREO ${flight.id} ${type} - ${
                        meta[`flight_${flight.id}_section_${section.id}_class`]
                      } - ${
                        meta[`flight_${flight.id}_section_${section.id}_origin`]
                      } <i class="fas fa-plane" style="margin: 0 2px; font-size: 8px; top:-1px; position: relative;"></i>  ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_destination`
                        ]
                      }`,
                    });
                  }
                });
              });
            }
          }

          // SERVIÇOS

          if (p == "service") {
            JSON.parse(meta.service_rows).forEach((row) => {
              if (meta[`service_${row.id}_name`] != undefined) {
                allProducts.push({
                  color: "orange",
                  name: meta[`service_${row.id}_name`],
                });
              }
            });
          }
        });
      }

      return allProducts.length > 0
        ? allProducts
        : [{ color: "", name: "SEM PRODUTOS" }];
    },
  },
};
</script>

<style lang="sass" scoped>
.ant-alert-info
    font-size: 12px
.label
    font-size: 11px
    font-weight: 600
    color: #444
    display: block
    z-index: 1
.quillWrapper
    border: 1px solid #eee
.ant-radio-wrapper
    font-size: 13px
    font-weight: 500
</style>
